<template>
  <div class="announce-detail">
    <el-tabs style="margin-bottom: 20px" v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane :label="tab.label" :name="tab.name" v-for="(tab, index) in tabArr" :key="index">
      </el-tab-pane>
    </el-tabs>
    <div class="list-content" v-loading="loading">
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%">
        <!-- 操作 -->
        <el-table-column v-if="pageData.tab === 'dissent'" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <!-- TODO：弹窗 -->
              <el-button size="small" @click="viewContent(scope.row.content)" type="text">查看</el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <el-table-column v-for="(th, index) in thead[pageData.tab]" :key="index" :prop="th.prop"
          :label="th.label" :min-width="th.minWidth || ''" :show-overflow-tooltip="th.showOverflowTooltip">
          <template slot-scope="scope">
            <!-- 头像 -->
            <list-image v-if="th.prop === 'avatar'" :src="scope.row[th.prop]" :width="32" :height="32">
            </list-image>
            <!-- 性别（0:未填写，1:男，2:女） -->
            <span
              v-else-if="th.prop === 'gender'">{{scope.row.gender === 2 ? '女': ''}}{{scope.row.gender === 1 ? '男':''}}{{scope.row.gender === 0 ? '未填':''}}</span>
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <dissent-content-dialog v-model="dialog.show" :content="dialog.content"></dissent-content-dialog>
  </div>
</template>

<script>
import Pagination from "../../../../base/components/Default/Pagination";
import { getDetailList } from "../../api/announce/detail";
import DissentContentDialog from "../../components/Announce/DissentContentDialog";

export default {
  components: {
    DissentContentDialog,
    Pagination,
  },
  data() {
    return {
      loading: true,
      // dissent（异议） endorse（赞同）
      tabArr: [
        { label: "赞同人数", name: "endorse" },
        { label: "异议人数", name: "dissent" },
      ],
      activeName: "endorse",
      pageData: {
        tab: "endorse",
        page_size: 15,
      },
      thead: {
        endorse: [
          { label: "ID", prop: "id", minWidth: 120 },
          { label: "头像", prop: "avatar", minWidth: 80 },
          { label: "会员名称", prop: "nickname", minWidth: 110 },
          { label: "性别", prop: "gender", minWidth: 60 },
          { label: "会内职务", prop: "title_name", minWidth: 150 },
          {
            label: "异议内容",
            prop: "content",
            minWidth: 200,
            showOverflowTooltip: true,
          },
        ],
        dissent: [
          { label: "ID", prop: "id", minWidth: 120 },
          { label: "头像", prop: "avatar", minWidth: 80 },
          { label: "会员名称", prop: "nickname", minWidth: 110 },
          { label: "性别", prop: "gender", minWidth: 60 },
          { label: "会内职务", prop: "title_name", minWidth: 150 },
        ],
      },
      tbody: [],
      filterForm: {
        tab: "endorse",
        page_size: 15,
      },
      dialog: {
        show: false,
        content: "",
      },
    };
  },
  created() {
    this.getDetailList(this.pageData);
  },
  methods: {
    viewContent(content) {
      this.dialog.content = content;
      this.dialog.show = true;
    },
    getDetailList(query) {
      this.loading = true;
      getDetailList({
        id: this.$route.params.id,
        ...query,
      })
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleTabClick(e) {
      this.filterForm.tab = e.paneName;
      this.getDetailList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getDetailList(pageData);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
