<template>
  <el-dialog :visible="value" class="dialog-vertical" title="异议内容" @close="close">
    <div class="content">
      {{content}}
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: "DissentContentDialog",
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      content: {
        type: String,
      }
    },
    methods: {
      close() {
        this.$emit('input', false)
      }
    },
  }
</script>

<style lang="scss" scoped>
.content {
  max-height: 600px;
  overflow: auto;
}
</style>
